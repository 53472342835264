@tailwind base;
@tailwind components;
@tailwind utilities;

@import './sections/Header/Header.css';
@import './sections/Banner/Banner.css';
@import './sections/SectionVerticalSlider/SectionVerticalSlider.css';
@import './sections/Services/Services.css';
@import './sections/Samples/Samples.css';
@import './sections/AboutUs/AboutUs.css';
@import './components/Bg/Bg.css';
@import './sections/Footer/Footer.css';
@import './components/SwiperSliders/SwiperCards/SwiperCards.css';
@import './components/ImgSlider/SlidItem.css';
@import './sections/ContactUs/ContactUs.css';
@import './sections/Card/Cards.css';
@import './components/SwiperSliders/SwiperFreeMode/SwiperFreeMode.css';


.light {
  @apply bg-primary-50 text-primary-975
}
.dark {
  @apply bg-primary-975 text-primary-50
}

body {
  @apply font-vazir m-0
}

a:hover {
  @apply transition duration-500 ease-in-out text-primary-500;
}

@layer base {

}

.btn {
  @apply transition ease-in-out delay-150 hover:-translate-x-1 hover:scale-105 duration-700 bg-primary-500 text-lg max-lg:text-sm text-white rounded-md px-8 py-2 border-2 border-primary-400 hover:bg-primary-800 shadow-2xl shadow-primary-200 dark:shadow-gray-900;
}

.btn-outline {
  @apply text-lg max-lg:text-sm transition ease-in-out delay-150 hover:-translate-x-1 hover:scale-105 duration-700 border-2 rounded-md border-primary-400 px-8 py-2 hover:bg-primary-200 hover:border-primary-100 shadow-2xl shadow-primary-200 dark:shadow-gray-900 dark:hover:text-primary-950;
}

.scroll-to-top {
  direction: ltr !important;
  bottom: 10px !important;
  left: 10px !important;
  right: unset;
  border-radius: 100% !important;
  background-color: rgb(0, 0, 77) !important;
  @apply flex justify-center items-center fixed 
}
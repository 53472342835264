.swiper-vertical {
  @apply w-full h-full bg-inherit
}
.swiper-slide-vertical {
  @apply flex flex-col justify-center items-center text-xl text-center
}
.swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets{
  direction: ltr;
  @apply absolute left-5 top-[50%]
}
.vertical-card{
  box-shadow: 5px 5px 15px 15px rgb(241, 241, 241);
@apply bg-white px-6 mx-12 space-y-8 pt-6 pb-12 dark:bg-gray-700 dark:shadow-2xl dark:shadow-gray-600
}
.vertical-card-header{
  @apply flex justify-between items-center
}
.vertical-card-header-right{
  @apply text-sm lg:text-lg text-gray-400
}
.vertical-card-header-left{
  @apply text-sm lg:text-lg text-primary-800 font-bold dark:text-primary-300
}
.vertical-card-title{
  @apply text-lg lg:text-2xl font-bold text-primary-900 dark:text-primary-200
}
.vertical-card-text{
  @apply leading-loose max-sm:text-justify  pb-10  text-sm lg:text-xl
}
.vertical-footer{
  @apply z-50 relative flex justify-center items-end 
}
.vertical-footer-content{
  @apply  border-2 border-primary-800 flex justify-center items-center rounded-full text-sm lg:text-3xl font-bold p-2 w-12 h-12 lg:w-16 lg:h-16 bg-primary-800 text-white
}
.swiper-vertical .swiper-pagination-bullet{
  @apply w-[15px] h-[15px] bg-primary-800
}
.vertical-bg-wavy{
  @apply absolute top-5 -right-2 w-52 h-52 pattern-wavy pattern-primary-800 dark:pattern-primary-500 pattern-bg-white dark:pattern-bg-primary-975 pattern-size-6 opacity-90 rounded-full;

}
.vertical-bg-dots{
  @apply pattern-cross pattern-primary-700 pattern-bg-primary-50 dark:pattern-bg-primary-975 dark:pattern-primary-200 pattern-size-6 opacity-100 absolute bottom-0 left-[1%] w-56 h-56 rounded-full 
}
.num{
 @apply absolute -bottom-[30px]
}
.svs{
    @apply max-lg:overflow-hidden  relative mt-20 mb-8 lg:mx-16 mx-3 flex lg:flex-row flex-col-reverse justify-between items-center gap-6
}
.svs-slider{
    @apply h-[500px]  relative lg:w-3/5 xl:w-2/4 max-sm:mr-10
}
.svs-body{
    @apply flex  flex-col justify-start gap-5 lg:w-2/5 xl:w-2/4
}
.svs-title{
    @apply text-4xl font-bold text-center lg:text-right
}
.svs-text{
    @apply text-xl dark:text-secondary-300 text-secondary-600 lg:max-w-lg text-center lg:text-right max-lg:text-center max-lg:text-base leading-normal  max-md:text-justify max-sm:text-justify
}
.contact-wrapper{
    @apply  flex lg:flex-row flex-col items-center justify-between lg:mx-6 mx-1 
}
.contact-info{
    @apply space-y-6 text-center lg:text-right
}
.contact-title{
    @apply dark:text-primary-400 text-3xl font-bold text-primary-900
}
.contact-body{
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-5
}
.contact-text{
    @apply text-xl font-bold mx-2 max-sm:text-lg
}
.contact-description{
    @apply dark:text-gray-400 text-lg max-sm:text-base text-gray-700 mt-3 mx-4
}
.contact-social{
    @apply flex  gap-3 my-6 justify-center items-center lg:justify-start
}
.contact-social-link{
    @apply transition duration-700 ease-in-out  hover:bg-primary-200 hover:text-primary-800 bg-primary-800 text-white p-2 w-12 h-12 rounded-full
}
.contact-form-wrapper{
    @apply overflow-hidden lg:w-3/4 relative
}
.contact-form{
    @apply  dark:text-gray-900 relative dark:bg-primary-800 border-2 shadow-lg shadow-primary-200
    dark:shadow-primary-800 dark:border-primary-800  my-10 px-8 py-10 bg-primary-100  lg:w-[90%] mx-auto max-sm:mx-0
}
.form-body{
    @apply space-y-10 z-50
}
.form-row {
    @apply flex flex-col max-md:space-y-10 md:flex-row md:gap-3 
}
.form-lable{
    @apply text-base font-bold max-sm:text-base dark:text-primary-100 mb-1
}
.input-group{
    @apply flex flex-col w-full
}
.form-input-required{
    @apply text-red-500 font-bold text-xl
}
.form-input{
    @apply caret-primary-500 focus:outline-primary-500 shadow-primary-500  dark:bg-secondary-300 dark:border-secondary-300 dark:focus:bg-secondary-200 dark:placeholder:text-secondary-50
}
.form-input-error{
    @apply caret-red-500 focus:outline-red-500 border-red-500 shadow-red-200 dark:border-secondary-300
}
.form-input-success{
    @apply caret-green-500 focus:outline-green-500 border-green-500 shadow-green-200 dark:border-secondary-300
}
.form-input-default{
    @apply mb-2 px-2 py-1 border-2 rounded-md shadow-md
}
.form-error-msg{
    @apply text-red-700 pr-3 text-base
}
.form-btn-disabled{
    @apply bg-secondary-200 text-primary-900 border-2 border-secondary-200 px-10 py-2 rounded-md font-bold 
}
.form-btn-submit{
    @apply  text-secondary-50 px-10 py-2 rounded-md font-bold hover:bg-primary-800 bg-primary-600
}
.form-btn-loadig{
    @apply flex items-center bg-primary-400  border-2 border-primary-400 py-2 px-6 rounded-md
}
.form-msg{
    @apply bg-green-100 py-6 text-center w-[80%] mx-auto rounded-md border-2 border-green-200
}
.swiper-card{
  height: unset;
  @apply min-h-max rounded-lg p-3 flex flex-wrap flex-col items-stretch justify-center 
}
.swiper-card-header{
  @apply flex justify-between
}
.swiper-card-header-right{
  @apply text-sm font-bold text-secondary-500
}
.swiper-card-header-left{
  @apply font-bold text-sm text-primary-600
}
.swiper-card-body{
  @apply p-6 text-center flex justify-center flex-col items-center space-y-2 overflow-hidden
}
.swiper-card-img{
  @apply flex justify-center items-center w-11 h-11 bg-primary-200  rounded-full
}
.swiper-card-text{
  @apply flex-1 text-sm  mt-2 max-sm:text-justify
}
.swiper-card:nth-child(odd) {
  @apply bg-primary-100
}
.swiper-card:nth-child(even) {
  @apply bg-primary-200
}

.about{
    @apply flex xl:flex-row flex-col my-4 p-6 bg-primary-200 dark:bg-primary-900  relative gap-5
}
.about-body{
    @apply  flex flex-col items-center justify-center
}
.about-title{
    @apply text-center text-4xl
}
.about-title-brand{
    @apply text-rose-700
}
.about-text{
    @apply text-center dark:text-secondary-50 mb-12 mt-6 max-w-4xl max-sm:text-justify leading-loose
}
.about-cards{
    @apply flex lg:flex-row flex-col gap-2 justify-evenly items-center 
}
.about-card{
    @apply h-full flex flex-col flex-1 relative dark:bg-primary-700 bg-white text-center 
}
.about-card-header{
    @apply flex justify-between items-center p-8  
}
.about-card-img{
    @apply flex justify-center items-center w-28 h-28 border-2 border-primary-50  rounded-full
}
.about-card-header-right{
    @apply  text-primary-600 text-3xl font-bold dark:text-primary-300
}
.about-card-header-left{
    @apply  text-primary-400 font-bold text-lg dark:text-primary-100
}
.about-card-body{
    @apply flex gap-9  flex-1 justify-center items-center flex-col
}
.about-card-text{
    @apply text-gray-500 dark:text-gray-200 pb-6  leading-7 max-sm:text-justify
}
.about-card-social{
    @apply relative  flex flex-row justify-center pb-16 gap-6 mb-6
}
.about-social-link{
    @apply hover:bg-primary-900 hover:text-primary-50 hover:rounded-sm transition ease-in delay-150 hover:-translate-y-1 hover:scale-105 duration-700 bg-primary-100 text-primary-900 p-1 w-8 h-8
}
.about-card-footer{
    @apply w-[25%] h-[5px] absolute left-0 bottom-[7%] bg-primary-800 dark:bg-primary-200
}
.header{
    @apply px-4 items-center  py-4   absolute z-50 top-0 left-0 right-0 w-full
}
.nav{
    @apply flex justify-between items-center
}
.nav-items{
    @apply dark:text-primary-700 text-primary-975 flex flex-1 flex-row text-lg justify-center items-center gap-10 max-lg:hidden
}

.nav-link{
    @apply dark:text-primary-700 text-primary-975 border-r-2  border-primary-900 pr-2 hover:border-primary-400
}
.nav-divide{
    @apply  divide-y-2 divide-primary-50
}
.logo{
    @apply flex items-center
}
.logo-name{
    @apply dark:text-white text-xl font-extralight
}

.btn-darkMode{
    @apply hover:text-primary-500 text-3xl  
}